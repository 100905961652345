<template>
  <div>
    <div
      class="navbar"
      @mouseleave="hideMenu"
      :class="lastScrollTop > 0 ? 'black' : ''"
      v-show="showMenu && name !== '/pdfViewer'"
    >
      <div class="flexcenter">
        <img src="@/assets/img/logo.png" alt="" @click="goIndex" />
        <div class="menu-box">
          <div class="menus">
            <div
              class="menuItem"
              v-for="(item, index) in menuItems"
              :key="index"
              @mouseover="showMenuFun(item)"
            >
              <span @click="goUrl(item)" :class="!item.subItems ? 'sub' : ''">
                {{ item.title }}
              </span>
              <i
                :class="
                  item.showMenu ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
                "
                v-if="item.subItems"
              ></i>
              <transition name="el-fade-in-linear">
                <div class="mask" v-show="item.showMenu"></div>
              </transition>
              <transition name="el-fade-in">
                <div
                  class="children-container"
                  v-show="item.showMenu"
                  @mouseleave="hideMenu(item)"
                >
                  <div class="children">
                    <div
                      class="child"
                      v-for="(item2, index2) in item.subItems"
                      :key="index2"
                    >
                      <span @click="goUrl(item2)">{{ item2.title }}</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {},
  data() {
    return {
      activeIndex: "",
      menuItems: [
        { index: "/", title: "首页" },
        {
          index: "/gongsijieshao",
          title: "公司介绍",
          subItems: [
            {
              index: "/aboutUs",
              title: "关于我们",
            },
            {
              title: "数字画册",
              url: "https://flbook.com.cn/c/FPafNHcLpl",
            },
            {
              title: "IPO倒计时",
              redirectUrl: "/ipoWait",
            },
          ],
        },
        {
          index: "/Advantages",
          title: "企业优势",
          subItems: [
            {
              index: "/businessAdvantages",
              title: "业务优势",
            },
            {
              index: "/comprehensiveAdvantages",
              title: "综合优势",
            },
          ],
        },
        {
          index: "4",
          title: "项目案例",
          subItems: [
            {
              title: "预建设",
              url: "",
            },
          ],
        },
        {
          index: "/companyNews",
          title: "公司新闻",
        },
        {
          index: "6",
          title: "了解更多",
          subItems: [
            {
              index: "/joinUs",
              title: "加入我们",
            },
            {
              index: "/agentCooperation",
              title: "代理合作",
            },
            { index: "/cooperativePartner", title: "合作伙伴" },
          ],
        },
      ],
      showMenu: true,
      lastScrollTop: 0,
      name: this.$route.path,
    };
  },
  methods: {
    showMenuFun(item) {
      this.menuItems.forEach((i) => {
        this.$set(i, "showMenu", false);
      });
      this.$set(item, "showMenu", true);
    },
    hideMenu() {
      this.menuItems.forEach((i) => {
        this.$set(i, "showMenu", false);
      });
    },
    goUrl(item) {
      if (!item.subItems) {
        this.hideMenu();
        if (item.url) {
          window.open(item.url, "_blank");
        } else if (item.redirectUrl) {
          const url = this.$router.resolve({
            path: item.redirectUrl,
          });
          window.open(url.href, "_blank");

        } else if (item.index && item.index !== this.$route.path) {
          this.$router.push(item.index);
        } else if (item.index) {
          location.reload();
        } else {
          this.$message({
            showClose: true,
            message: "功能开发中，敬请期待",
          });
        }
      }
    },
    goIndex() {
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    this.$api
      .selectLink({
        type: "2",
      })
      .then((res) => {
        this.menuItems[3].subItems[0].url = res[0]?.link;
      });
    let lastScrollTop = 0;
    window.addEventListener("scroll", () => {
      let currentScrollTop = document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        this.showMenu = false;
      }
      // 如果当前滚动位置小于前一次滚动位置，说明是向上滚动
      else if (currentScrollTop < lastScrollTop) {
        this.showMenu = true;
      }
      // 如果两次滚动位置相等，则不输出

      // 更新为当前滚动位置，以便下次比较
      lastScrollTop = currentScrollTop;
      this.lastScrollTop = lastScrollTop;
    });
  },
};
</script>

<style scoped lang="less">
.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  overflow: visible;
  line-height: 100%;
  z-index: 500 !important;
  height: 80px;
  // background: linear-gradient(180deg, #000, #d9d9d900);
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 4.6875vw;
    top: 0;
    left: 0;
    opacity: 0.25;
    height: 80px;
    background: linear-gradient(180deg, #000, #d9d9d900);
  }
  // opacity: 0.25;
  .flexcenter {
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    z-index: 2;
    img {
      display: block;
      width: 107px;
      height: 41px;
      cursor: pointer;
    }
  }
}

.black {
  background: #000;
}

.menus {
  position: relative;
  margin-left: 20px;
  // transition: all 2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  .menuItem {
    // height: 18px;
    margin-right: 30px;
    padding: 0 0 0 40px;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    &:last-child {
      margin-right: 0;
      border-left: 1px solid #fff;
    }
    span {
      display: inline-block;
      font-size: 18px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      white-space: nowrap;
      cursor: pointer;
    }
    .sub {
      &:hover {
        color: #009ade;
      }
    }
    i {
      margin: 0 0 0 20px;
    }
    .children-container {
      position: absolute;
      z-index: 999;
      top: 200%;
      left: 0;
      transition: all 0.5s ease;
      padding: 0 0 0 60px;
      .child {
        // font-size: 18px;
        // font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        // font-weight: 400;
        // text-align: left;
        // color: #ffffff;
        line-height: 38px;
        margin: 0 0 10px;
        span {
          &:hover {
            color: #009ade;
          }
        }
      }
    }
    .mask {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.7);
      pointer-events: none;
      transition: all 0.5s ease;
    }
  }
}

::v-deep .el-menu-demo {
  display: flex;
  align-items: center;
  background: none;
  margin: 20px 0;
  .el-menu-item {
    height: auto;
    line-height: 18px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    background: none;
    padding: 0 50px 0 30px;
    &:last-child {
      border-left: 1px solid #fff;
    }
  }
  .el-submenu__title {
    font-size: 18px !important;
    border-bottom: none !important;
  }
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
  color: #fff;
}

::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
  color: #fff;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff !important;
}

::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: inherit !important;
}

::v-deep .el-submenu__title:focus,
.el-submenu__title:hover {
  background: inherit !important;
}

::v-deep .el-submenu__title i {
  color: #fff;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: linear-gradient(180deg, #000, rgba(0, 0, 0, 0.01)) !important;
}
</style>
