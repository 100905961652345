<template>
  <div id="app">
    <navMenu v-if="showMenu"></navMenu>
    <div class="appContent">
      <router-view />
    </div>
  </div>
</template>
<script>
import navMenu from "./components/navMenu.vue";
export default {
  name: "app",
  components: {
    navMenu,
  },
  data() {
    return {
      lastScrollTop: 0,
      showMenu: true,
    };
  },
  mounted() {
    this.$watch("$route", (newRoute, oldRoute) => {
      if (newRoute.path === "/ipoWait" || newRoute.path === "/pdfViewer") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
      // 在这里执行你需要的操作
    }); // 如果希望一开始就触发一次监听器，可以设置immediate为true
    this.$api.companyProfileselect().then((res) => {
      if (res) {
        sessionStorage.setItem(
          "companyInfo",
          JSON.stringify(
            res.find((i) => {
              return i.isHeadquarters == 1;
            })
          )
        );

        sessionStorage.setItem("companyList", JSON.stringify(res));
      } else {
        sessionStorage.removeItem("companyInfo");
        sessionStorage.removeItem("companyList");
      }
    });
  },
  methods: {},
};
</script>
<style lang="less">
@font-face {
  font-family: "DIN-Regular";
  src: url("~@/static/DIN-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PingFang SC";
  src: url("~@/static/PingFang-SC-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  font-family: "PingFang SC";
  user-select: none;
}

html,
body {
  width: 100%;
  background: none;
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  background: #000;
  color: #fff;
  font-size: 1vw;
  letter-spacing: normal;
  overflow-x: hidden;
  font-family: "PingFang SC";
}

#app {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #000;
  font-family: "PingFang SC";
  .appContent {
    margin: auto;
    padding: 0 160px;
    overflow: hidden;
  }
}

.el-menu--horizontal {
  background: inherit !important;
}

.el-menu--popup {
  background: inherit !important;
  color: #fff !important;
  box-shadow: initial !important;
  .el-menu-item {
    background: inherit !important;
    color: #fff !important;
    // text-align: center;
    font-size: 18px;
  }
}

.aboutUs .header .banner {
  .p {
    width: 940px;
    font-size: 20px !important;
    font-weight: 400 !important;
    text-align: justify !important;
    color: #ffffff !important;
    line-height: 30px !important;
    p {
      margin-bottom: 30px;
      position: relative;
      bottom: -50px;
      opacity: 0;
      animation: fade-in-from-bottom 1.5s ease-out forwards;
    }
  }
}
.el-drawer__header,
.el-dialog__header {
  padding: 38px 60px 28px 0 !important;
}

.el-dialog__close,
.el-drawer__close-btn {
  font-size: 35px;
  color: #d5dade;
  &:hover {
    color: #009ade !important;
  }
}

.el-dialog,
.el-drawer,
.el-drawer__header,
.el-drawer__body,
.el-dialog__header,
.el-dialog__body,
.el-drawer__header {
  background: #3b3c44;
  margin-bottom: 0 !important;
  p {
    margin: 0 0 40px !important;
    font-family: "PingFang SC" !important;
    span {
      color: #fff !important;
      font-size: 20px !important;
      line-height: 30px !important;
      font-family: "PingFang SC" !important;
    }
    color: #fff !important;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto 40px !important;
  }
}

.el-drawer {
  // padding: 0 100px !important;
  margin: 0 !important;
  width: 1160px !important;
  background-color: #3b3c44 !important;
  height: 100vh !important;
  padding: 0 10px 0 0;
  .drawer_title {
    font-size: 40px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 56px;
  }

  .el-drawer__body {
    padding: 0 100px !important;
  }

  .name {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 30px;
    padding: 20px 0;
  }
  .time {
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    color: #ffffff;
    line-height: 30px;
  }
  .drawer_content {
    padding: 80px 0;
  }
}

.el-drawer__body::-webkit-scrollbar {
  width: 8px; /* 滚动条宽度 */
}

/* 定义滚动条轨道的样式 */
.el-drawer__body::-webkit-scrollbar-track {
  background-color: #3b3c44;
  // background-color: rgba(0, green, blue, alpha); /* 轨道颜色 */
}

/* 定义滚动条滑块的样式 */
.el-drawer__body::-webkit-scrollbar-thumb {
  background-color: #898a8f; /* 滑块颜色 */
  border-radius: 4px; /* 滑块圆角 */
}

.el-dialog__close {
  color: #fff !important;
}

.top_bg {
  position: fixed;
  width: 100vw;
  height: 671px;
  top: 0;
  left: 0;
  z-index: 99;
  object-fit: cover;
  pointer-events: none; /* 关闭指针事件 */
}

.bottom_bg {
  position: fixed;
  width: 1920px;
  height: 422px;
  bottom: 0;
  right: 0;
  object-fit: cover;
  pointer-events: none; /* 关闭指针事件 */
  z-index: 99;
}

@keyframes fade-in-from-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-up {
  margin-top: 50px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease-in-out;
}

.animate-up.active {
  opacity: 1;
  transform: translateY(0);
  margin-top: 0;
}

.el-select-dropdown__list {
  background-color: #000;
  color: #fff;
  padding: 5px !important;
  .el-select-dropdown__item {
    height: 46px;
    padding: 0 20px;
    line-height: 46px;
    height: 46px;
    margin: 0 0 5px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: block;
      font-size: 20px;
      line-height: 46px;
      height: 46px;
      color: #fff;
      font-weight: normal;
    }
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #009ade !important;
    color: #fff;
    span {
      color: #fff !important;
    }
  }

  .el-select-dropdown__item.selected {
    background: none;
    span {
      color: #009ade;
      font-weight: normal;
    }
  }
}

.el-select-dropdown {
  border-radius: 0 !important;
}

.el-select .el-input .el-select__caret {
  color: #fff !important;
}

.el-select .is-focus .el-select__caret {
  color: #009ade !important;
}

.el-select-dropdown {
  .popper__arrow {
    opacity: 0;
  }
}
</style>
