import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/index2",
    name: "index2",
    component: () => import("../views/index2.vue"),
  },
  {
    path: "/index3",
    name: "index3",
    component: () => import("../views/index3.vue"),
  },
  {
    path: "/businessAdvantages",
    name: "businessAdvantages",
    component: () => import("../views/businessAdvantages.vue"),
  },
  {
    path: "/comprehensiveAdvantages",
    name: "comprehensiveAdvantages",
    component: () => import("../views/comprehensiveAdvantages.vue"),
  },
  {
    path: "/cooperativePartner",
    name: "cooperativePartner",
    component: () => import("../views/cooperativePartner.vue"),
  },
  {
    path: "/preConstruction",
    name: "preConstruction",
    component: () => import("../views/preConstruction.vue"),
  },
  {
    path: "/preConstructionProduct",
    name: "preConstructionProduct",
    component: () => import("../views/preConstructionProduct.vue"),
  },
  {
    path: "/preConstructionDetailList",
    name: "preConstructionDetailList",
    component: () => import("../views/preConstructionDetailList.vue"),
  },
  {
    path: "/preConstructionDetail",
    name: "preConstructionDetail",
    component: () => import("../views/preConstructionDetail.vue"),
  },
  {
    path: "/companyNews",
    name: "companyNews",
    component: () => import("../views/companyNews.vue"),
  },
  {
    path: "/industryNews",
    name: "industryNews",
    component: () => import("../views/industryNews.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/aboutUs.vue"),
  },
  {
    path: "/joinUs",
    name: "joinUs",
    component: () => import("../views/joinUs.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import("../views/contactUs.vue"),
  },
  {
    path: "/agentCooperation",
    name: "agentCooperation",
    component: () => import("../views/agentCooperation.vue"),
  },
  {
    path: "/pdfViewer",
    name: "pdfViewer",
    component: () => import("../views/pdfViewer.vue"),
  },
  {
    path: "/waiting",
    name: "waiting",
    component: () => import("../views/waiting.vue"),
  },
  {
    path: "/video",
    name: "video",
    component: () => import("../views/video.vue"),
  },
  {
    path: "/ipoWait",
    name: "ipoWait",
    component: () => import("../views/ipoWait.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to && from && to.path && to.path === from.path && to.path !== "/") {
    next(false); // Prevent navigation
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
  // script.js

  // document.addEventListener("DOMContentLoaded", function () {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add("active");
  //       } else {
  //
  //       }
  //     });
  //   });

  //   document.querySelectorAll(".animate-up").forEach((element) => {
  //     observer.observe(element);
  //   });
  // });
});

// 确保要调用 next() 方法，否则钩子就不会被 resolved

export default router;
